import { useEffect, useState, Fragment } from "react";
import {
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  AutoComplete,
  Select,
  Divider,
  Popconfirm,
  message,
  Upload,
  List,
  Image,
} from "antd";
import {
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { getLocalesFromDb } from "../../../../../utils/common/apiUtils";
import { BREADCRUMB_ADD_NEW_DISCOUNT_TOKEN } from "../../../../../utils/benefits/constant";
import {
  Modal,
  Button,
  Radio,
  ModalHeader,
} from "../../../../../components/customAntd";
import Dragger from "antd/es/upload/Dragger";
import { uploadFiles } from "../../../../../utils/common/apiUtils";

import Styles from "../styles/discountCodes.module.scss";

const { Item } = Form;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const NewCode = ({
  form,
  isOpen,
  onCancel,
  onSubmitClick,
  onSubmit,
  loading,
  isDataLoading,
  isEdit,
  merchantData,
  setMerchantData,
  editData,
  setHeroImage,
  heroImage,
}) => {
  const [files, setFiles] = useState([]);
  const [isHeroLoading, setIsHeroLoading] = useState(false);
  const [isHeroPreviewVisible, setHeroPreviewVisible] = useState(false);
  const [defaultLocale] = useState("en_US");
  const [availableLocales, setAvailableLocales] = useState([]);
  const discountType = Form.useWatch("discount_type", form);
  const unlimitedUse = Form.useWatch("unlimited_use", form);
  const useLimitPerPass = Form.useWatch("use_limited_pass", form);
  const frequencyUsage = Form.useWatch("frequency", form);
  const watchLocales = Form.useWatch("locales", form);
  const watchDefaultLanguage = Form.useWatch("defaultLocaleId", form);
  const { t } = useTranslation();

  useEffect(() => {
    if (unlimitedUse === "yes") {
      form.setFieldValue("use_limited_pass", null);
      form.setFieldValue("use_limited_total", null);
    }
  }, [unlimitedUse, form]);

  useEffect(() => {
    const getLocales = async () => {
      const data = await getLocalesFromDb();
      if (data) {
        const localesData = data.map((locale) => ({
          value: locale.localeId,
          label: `${locale.language.language} (${locale.country.country})`,
          isSelected: locale.localeId === defaultLocale,
        }));
        setAvailableLocales(localesData);
      }
    };
    getLocales();
  }, [defaultLocale]);

  useEffect(() => {
    // Set Available locales on editing the discount code
    if (isEdit && editData) {
      setAvailableLocales((prev) => {
        return prev.map((locale) => {
          return {
            ...locale,
            isSelected:
              !!editData.locales.find(
                (localeEl) => localeEl.localeId === locale.value
              ) || editData.defaultLocaleId === locale.value,
          };
        });
      });
    }
  }, [isEdit]);

  const handleDeleteClickHero = (fileName) => {
    setHeroImage([]);
    form.setFieldValue("hero_image", undefined);
  };

  // Change hero image
  const handleHeroImageChange = (data) => {
    setHeroImage([...data]);
    setFiles([...files, ...data]);
  };

  const handleBeforeUpload = async (file) => {
    const extension = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    const currentFileIndexOf = files.findIndex((i) => i.name === file.name);
    if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.ONLY")} JPG, JPEG and PNG ${t(
          "FILE_UPLOAD.EXTENSIONS_ALLOWED"
        )}`,
      });
      form.setFieldValue("hero_image", undefined);
      return Upload.LIST_IGNORE;
    } else if (currentFileIndexOf !== -1) {
      message.open({
        type: "error",
        content: t("FILE_UPLOAD.EXISTS"),
      });
      return Upload.LIST_IGNORE;
    } else if (file.size > 10000000) {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.SIZE_ERROR")} 10${t("MB")}`,
      });
      return Upload.LIST_IGNORE;
    } else {
      setIsHeroLoading(true);
      const uploadFile = await uploadFiles({
        fileFor: "benefit",
        isUploadIpfs: false,
        files: file,
      });

      setIsHeroLoading(false);
      if (uploadFile?.status === 200) {
        const uploadArray = [
          {
            name: uploadFile.data.s3Url.split("/").reverse()[0],
            resourceUrl: uploadFile.data.s3Url,
            type: "heroImage",
          },
        ];
        handleHeroImageChange(uploadArray);
      }
      setIsHeroLoading(false);
    }
  };

  // Updated locales state if someone changed language in the form
  const updateLocales = (value, prevValue) => {
    setAvailableLocales((prev) => {
      return prev.map((item) =>
        item.value === value
          ? {
              ...item,
              isSelected: true,
            }
          : {
              ...item,
              isSelected: item.value === prevValue ? false : item.isSelected,
            }
      );
    });
  };

  const onSelect = (value, option) => {
    setMerchantData((prev) =>
      prev.map((item) => {
        return {
          ...item,
          isSelected: item.id === option.id,
        };
      })
    );
  };
  //Setting available locales to default state after close, cancel or edit action
  const setLocales = () => {
    setAvailableLocales((prev) => {
      return prev.map((locale) => {
        return {
          ...locale,
          isSelected: locale.value === defaultLocale,
        };
      });
    });
  };

  return (
    <Modal
      title={
        <ModalHeader
          title={
            isEdit ? t("DISCOUNT_CODES.EDIT") : t("DISCOUNT_CODES.NEW_CODE")
          }
          breadcrumbs={BREADCRUMB_ADD_NEW_DISCOUNT_TOKEN(t)}
        />
      }
      open={isOpen}
      centered
      width={1200}
      onCancel={() => {
        onCancel();
        setLocales();
      }}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      footer={[
        <Button
          buttonType="cancel"
          onClick={() => {
            onCancel();
            setLocales();
          }}
          key="cancel-button"
        >
          {t("CANCEL")}
        </Button>,
        <Button
          onClick={() => {
            onSubmitClick();
            setLocales();
          }}
          loading={loading || isDataLoading}
          key="create-button"
        >
          {isDataLoading
            ? `${t("LOADING")}...`
            : isEdit
            ? t("DISCOUNT_CODES.UPDATE")
            : t("DISCOUNT_CODES.CREATE")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        disabled={loading || isDataLoading}
        requiredMark={false}
      >
        <Row gutter={52}>
          <Col xs={24} md={12}>
            <Item
              label={`${t("DEFAULT_LANGUAGE")}:`}
              name="defaultLocaleId"
              initialValue={defaultLocale}
              rules={[
                {
                  required: true,
                  message: t("DEFAULT_LANGUAGE_ERROR"),
                  whitespace: true,
                },
              ]}
            >
              <Select
                options={availableLocales.map((localEl) => {
                  return {
                    ...localEl,
                    disabled: localEl.isSelected,
                  };
                })}
                onChange={(value) => updateLocales(value, watchDefaultLanguage)}
              />
            </Item>
            <h3>{t("DISCOUNT")}</h3>

            {isEdit && (
              <Item name="id" hidden>
                <Input />
              </Item>
            )}

            <Item
              label={`${t("DISCOUNT_CODES.CODE_NAME_S")}`}
              name="code_name"
              rules={[
                {
                  required: true,
                  message: t("DISCOUNT_CODES.CODE_NAME_ERROR"),
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder={t("DISCOUNT_CODES.NAME_PLACEHOLDER")} />
            </Item>

            <Item
              label={`${t("DISCOUNT_CODES.DESC")}:`}
              name="code_description"
              rules={[
                {
                  required: true,
                  message: t("DISCOUNT_CODES.DESC_ERROR"),
                  whitespace: true,
                },
              ]}
            >
              <TextArea
                placeholder={t("DISCOUNT_CODES.DESC_PLACEHOLDER")}
                style={{ resize: "none" }}
              />
            </Item>
            <Item
              label={`${t("DISCOUNT_CODES.TITLE_PREFIX")}:`}
              name="code_short_description"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && value.length > 10) {
                      return Promise.reject(
                        new Error(
                          t("DISCOUNT_CODES.CODE_PREFIX_VALIDATION_ERROR")
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <TextArea
                placeholder={t("PREFIX_TITLE_VALIDATION_ERROR")}
                style={{ resize: "none" }}
              />
            </Item>
            <Item label={`${t("DISCLAIMER")}:`} name="disclaimer">
              <MDEditor
                overflow={false}
                placeholder={t("DISCOUNT_CODES.DISCLAIMER_PLACEHOLDER")}
                // value={value}
                // onChange={setValue}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
              />
              {/* <TextArea
                placeholder={t("DISCOUNT_CODES.DISCLAIMER_PLACEHOLDER")}
                style={{ resize: "none" }}
              /> */}
            </Item>

            <Item
              label={`${t("DISCOUNT_CODES.CODE_S")}:`}
              name="code"
              rules={[
                {
                  required: true,
                  message: t("DISCOUNT_CODES.ENTER_CODE"),
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder={t("DISCOUNT_CODES.CODE")} />
            </Item>

            <Item
              label={`${t("HERO_IMAGE")}:`}
              name="hero_image"
              extra={
                <>
                  <div>{t("RECOMMENDED_ASPECT_RATIO")}</div>
                  <div>
                    {t("FILE_TYPES_SUPPORTED")}: JPG, JPEG, PNG, GIF, SVG,{" "}
                    {t("MAX_SIZE")}: 1{t("MB")}
                  </div>
                </>
              }
              rules={[
                {
                  required: isEdit && heroImage.length !== 0 ? false : true,
                  message: t("UPLOAD_HERO_IMAGE"),
                },
              ]}
            >
              <Dragger
                showUploadList={false}
                beforeUpload={handleBeforeUpload}
                disabled={isHeroLoading}
                accept="image/jpg, image/jpeg, image/png"
                customRequest={() => null}
                fileList={files}
                maxCount={1}
              >
                {isHeroLoading ? (
                  <>
                    <LoadingOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                    <p className="ant-upload-hint">{`${t("UPLOADING")}...`}</p>
                  </>
                ) : (
                  <>
                    <UploadOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                    <p className="ant-upload-hint">{t("UPLOAD")}</p>
                  </>
                )}
              </Dragger>
            </Item>
            {heroImage.length !== 0 && (
              <Item label={`${t("HERO_IMAGE")}:`}>
                <List
                  dataSource={heroImage}
                  className="upload-list"
                  style={{ marginBottom: 20 }}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <DeleteOutlined
                          onClick={() => handleDeleteClickHero(item.name)}
                        />,
                        <EyeOutlined
                          onClick={() =>
                            setHeroPreviewVisible(!isHeroPreviewVisible)
                          }
                        />,
                      ]}
                    >
                      <div>
                        <span className="item-index">{index + 1}.</span>{" "}
                        <span className="item-name">
                          {item.name ?? t("HERO_IMAGE_FILE")}
                        </span>
                      </div>
                    </List.Item>
                  )}
                />
              </Item>
            )}
            {heroImage[0]?.resourceUrl && (
              <Image
                className={Styles.imagePreview}
                src={heroImage[0].resourceUrl}
                preview={{
                  visible: isHeroPreviewVisible,
                  onVisibleChange: (visible, prevVisible) =>
                    setHeroPreviewVisible(visible),
                }}
              />
            )}

            <Item
              label={`${t("DISCOUNT_CODES.TYPE")}:`}
              name="discount_type"
              rules={[
                {
                  required: true,
                  message: t("DISCOUNT_CODES.TYPE_ERROR"),
                  whitespace: true,
                },
              ]}
            >
              <Radio.Group style={{ width: "100%" }}>
                <Row gutter={20}>
                  <Col xs={18} md={8}>
                    <Radio style={{ width: "100%" }} value="flat">
                      {t("DISCOUNT_CODES.FLAT_DISCOUNT")}
                    </Radio>
                  </Col>
                  <Col xs={18} md={8}>
                    <Radio style={{ width: "100%" }} value="percentage">
                      {t("DISCOUNT_CODES.PERCENTAGE_DISCOUNT")}
                    </Radio>
                  </Col>
                  <Col xs={18} md={8}>
                    <Radio style={{ width: "100%" }} value="partner">
                      {t("DISCOUNT_CODES.PARTNER_DISCOUNT")}
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Item>

            {discountType && discountType !== "partner" && (
              <Item
                label={`${t("DISCOUNT_CODES.AMOUNT")}:`}
                name="discount_amount"
                rules={[
                  {
                    required: true,
                    message: t("DISCOUNT_CODES.AMOUNT_ERROR"),
                  },
                ]}
              >
                <Input
                  type="number"
                  addonAfter={
                    discountType ? (discountType === "flat" ? "" : "%") : ""
                  }
                  onWheel={(e) => e.target.blur()}
                  min={0}
                  placeholder="0"
                />
              </Item>
            )}

            {discountType && discountType === "partner" && (
              <Item
                label={`${t("DISCOUNT_CODES.PARTNER_URL")}:`}
                name="partner_redirecturl"
                rules={[
                  {
                    required: true,
                    message: t("DISCOUNT_CODES.PARTNER_URL_ERROR"),
                  },
                ]}
              >
                <Input type="url" onWheel={(e) => e.target.blur()} />
              </Item>
            )}
            <Item
              label={`${t("REDEEMABLE_PERIOD")}:`}
              name="redeemable_period"
              rules={[
                {
                  required: true,
                  message: t("REDEEMABLE_PERIOD_ERROR"),
                },
              ]}
            >
              <RangePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD HH:mm"
                disabledDate={(current) => {
                  return (
                    current && current < dayjs().subtract(1, "day").endOf("day")
                  );
                }}
                placeholder={[t("START_DATE"), t("END_DATE")]}
                showTime={{ format: "HH:mm" }}
              />
            </Item>

            <Item
              label={`${t("DISCOUNT_CODES.UNLIMITED_USE")}:`}
              name="unlimited_use"
              rules={[
                {
                  required: true,
                  message: t("DISCOUNT_CODES.UNLIMITED_USE_ERROR"),
                },
              ]}
            >
              <Radio.Group style={{ width: "100%" }}>
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value="yes">
                      {t("YES")}
                    </Radio>
                  </Col>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value="no">
                      {t("NO")}
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Item>

            {unlimitedUse === "no" && (
              <>
                <Item
                  label={`${t("DISCOUNT_CODES.USE_LIMIT")}:`}
                  name="use_limited_pass"
                  rules={[
                    {
                      required: true,
                      message: t("DISCOUNT_CODES.ENTER_USE_LIMIT"),
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="0"
                    onWheel={(e) => e.target.blur()}
                  />
                </Item>
                <Item
                  label={`${t("DISCOUNT_CODES.TOTAL_USE_LIMIT")}:`}
                  name="use_limited_total"
                  rules={[
                    {
                      required: true,
                      message: t("DISCOUNT_CODES.ENTER_TOTAL_USE_LIMIT"),
                    },
                    ({ getFieldValue }) => ({
                      validator() {
                        if (
                          Number(getFieldValue("use_limited_total")) <
                          Number(useLimitPerPass)
                        ) {
                          return Promise.reject(
                            new Error(t("DISCOUNT_CODES.TOTAL_USE_LIMIT_ERROR"))
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="0"
                    onWheel={(e) => e.target.blur()}
                  />
                </Item>
              </>
            )}

            <Item
              label={`${t("DISCOUNT_CODES.FREQUENCY_CAP")}:`}
              name="frequency"
            >
              <Select
                defaultValue="none"
                style={{ width: 200 }}
                className="mr-2"
                placeholder={t("DISCOUNT_CODES.SELECT_FREQUENCY_CAP")}
                options={[
                  {
                    value: "none",
                    label: t("NONE"),
                  },
                  {
                    value: "daily",
                    label: t("DISCOUNT_CODES.DAILY"),
                  },
                  {
                    value: "weekly",
                    label: t("DISCOUNT_CODES.WEEKLY"),
                  },
                  {
                    value: "monthly",
                    label: t("DISCOUNT_CODES.MONTHLY"),
                  },
                ]}
              />
            </Item>

            {frequencyUsage && frequencyUsage !== "none" && (
              <Item
                label={`${t("DISCOUNT_CODES.FREQUENCY_LIMIT")}:`}
                name="frequency_limit"
                rules={[
                  {
                    required: true,
                    message: t("DISCOUNT_CODES.ENTER_FREQUENCY_LIMIT"),
                  },
                ]}
              >
                <Input type="number" min={1} placeholder="1" />
              </Item>
            )}

            <Item
              label={`${t("STATUS")}:`}
              name="status"
              rules={[
                {
                  required: true,
                  message: t("SELECT_STATUS"),
                },
              ]}
            >
              <Radio.Group style={{ width: "100%" }}>
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value="draft">
                      {t("DRAFT")}
                    </Radio>
                  </Col>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value="active">
                      {t("ACTIVE")}
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Item>
            <Item label={`${t("ACTIVE_DATE")}:`} name="active_date">
              <DatePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD HH:mm"
                disabledDate={(current) => {
                  return (
                    current && current < dayjs().subtract(1, "day").endOf("day")
                  );
                }}
                placeholder={[t("ACTIVE_DATE")]}
                showTime={{ format: "HH:mm" }}
              />
            </Item>
            <Item
              label={`${t("MEMBERSHIP_REQUIRED")}:`}
              name="membershipCriteria"
              rules={[
                {
                  required: true,
                  message: t("MEMBERSHIP_STATUS"),
                },
              ]}
            >
              <Radio.Group style={{ width: "100%" }}>
                <Row gutter={[20, 20]}>
                  <Col xs={24} md={8}>
                    <Radio style={{ width: "100%" }} value="NotRequired">
                      {/* {t("NO")} */}
                      {t("NOT_REQUIRED")}
                    </Radio>
                  </Col>
                  <Col xs={24} md={8}>
                    <Radio style={{ width: "100%" }} value="Visible">
                      {/* {t("YES")} */}
                      {t("VISIBLE")}
                    </Radio>
                  </Col>
                  <Col xs={24} md={8}>
                    <Radio style={{ width: "100%" }} value="NotVisible">
                      {/* {t("YES")} */}
                      {t("NOT_VISIBLE")}
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Item>
            <Item
              label={`${t("SHOWIMAGES_ONLY")}:`}
              name="show_images_only"
              rules={[
                {
                  required: true,
                  message: t("SHOWIMAGES_ONLY_ERROR"),
                },
              ]}
            >
              <Radio.Group style={{ width: "100%" }}>
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value={false}>
                      {t("NO")}
                    </Radio>
                  </Col>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value={true}>
                      {t("YES")}
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Item>
            <Form.List name="locales">
              {(fields, { add, remove }) => (
                <div className="languageOptions">
                  <h3 className="heading">{t("LANGUAGE_OPTIONS.HEADING")}</h3>
                  <p className="info">{t("LANGUAGE_OPTIONS.DESCRIPTION")}</p>
                  {fields.map(({ key, name }, index) => (
                    <Fragment key={key}>
                      <Item
                        label={`${t("LANGUAGE")} :`}
                        name={[name, "localeId"]}
                        rules={[
                          {
                            required: true,
                            message: t("LANGUAGE_OPTIONS.SELECT_LANGUAGE"),
                          },
                        ]}
                      >
                        <Select
                          options={availableLocales.map((localEl) => ({
                            ...localEl,
                            disabled: localEl.isSelected,
                          }))}
                          onChange={(value) =>
                            updateLocales(value, watchLocales[index].localeId)
                          }
                          placeholder={t("SELECT")}
                        />
                      </Item>
                      <Item
                        label={`${t("LANGUAGE_OPTIONS.NAME")} :`}
                        name={[name, "title"]}
                        rules={[
                          {
                            required: true,
                            message: t("LANGUAGE_OPTIONS.ENTER_NAME"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t(
                            "DISCOUNT_CODES.LOCALISED_NAME_PLACEHOLDER"
                          )}
                        />
                      </Item>
                      <Item
                        label={`${t("LANGUAGE_OPTIONS.DESC")} :`}
                        name={[name, "description"]}
                        rules={[
                          {
                            required: true,
                            message: t("LANGUAGE_OPTIONS.ENTER_DESC"),
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input.TextArea
                          placeholder={t(
                            "DISCOUNT_CODES.LOCALISED_DESCRIPTION_PLACEHOLDER"
                          )}
                        />
                      </Item>
                      <Item
                        label={`${t("DISCOUNT_CODES.LOCALISED_DISCLAIMER")} :`}
                        name={[name, "disclaimer"]}
                      >
                        <MDEditor
                          overflow={false}
                          placeholder={t(
                            "DISCOUNT_CODES.LOCALISED_DISCLAIMER_PLACEHOLDER"
                          )}
                          // value={value}
                          // onChange={setValue}
                          previewOptions={{
                            rehypePlugins: [[rehypeSanitize]],
                          }}
                        />
                        {/* <TextArea
                          placeholder={t(
                            "DISCOUNT_CODES.LOCALISED_DISCLAIMER_PLACEHOLDER"
                          )}
                          style={{ resize: "none" }}
                        /> */}
                      </Item>
                      {fields.length > 0 && (
                        <Divider orientation="center">
                          <Popconfirm
                            title={t("LANGUAGE_OPTIONS.CONFIRM_DELETE")}
                            onConfirm={() => {
                              setAvailableLocales((prev) => {
                                return prev.map((item) =>
                                  item.value === watchLocales[index].localeId
                                    ? {
                                        ...item,
                                        isSelected: false,
                                      }
                                    : item
                                );
                              });
                              remove(name);
                            }}
                            onCancel={() => {}}
                            okText={t("YES")}
                            cancelText={t("NO")}
                          >
                            <DeleteOutlined
                              className="deleteIcon"
                              style={{ fontSize: 24 }}
                            />
                          </Popconfirm>
                        </Divider>
                      )}
                    </Fragment>
                  ))}

                  <Button
                    type="primary"
                    className="add-locale-button"
                    onClick={fields.length >= 3 ? () => {} : () => add()}
                    icon={<PlusOutlined />}
                    disabled={fields.length >= 3}
                  >
                    {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
                  </Button>
                </div>
              )}
            </Form.List>
          </Col>

          <Col xs={24} md={12}>
            <h3>{t("MERCHANT.DETAILS")}</h3>

            <Item
              label={`${t("MERCHANT.NAME")} :`}
              name="merchant_name"
              rules={[
                {
                  required: true,
                  message: t("MERCHANT.SELECT_MERCHANT"),
                },
              ]}
            >
              <AutoComplete
                options={merchantData}
                onSelect={onSelect}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                placeholder={t("ADD_NAME")}
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default NewCode;
