import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Upload,
  message,
  Space,
  Image,
  List,
  Select,
} from "antd";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import {
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useLoadScript } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

import { BREADCRUMB_MERCHANT_CREATE } from "../../../../utils/merchants/constant";
import {
  Modal,
  Button,
  ModalHeader,
  Radio,
} from "../../../../components/customAntd";

import {
  uploadFiles,
  getLocalesFromDb,
} from "../../../../utils/common/apiUtils";
import { formatCategoriesForTreeSelect } from "../../../../utils/helper";
import GooglePlacesAutoComplete from "../../../../components/GooglePlacesAutoComplete/antPlaces";
import { useAppContext } from "../../../../components/context/app.context";
import CategoryTreeSelect from "../../../../components/categoryTreeSelect";
import MerchantLocaleDetails from "../localeDetails";

// import custom Style
import Styles from "../styles/merchant.module.scss";

const { Item } = Form;
const { Dragger } = Upload;

const NewMerchant = ({
  form,
  isOpen,
  onCancel,
  onSubmitClick,
  onSubmit,
  loading,
  isDataLoading,
  isEdit,
  setHeroImage,
  setLogoImage,
  heroImage,
  logoImage,
  locationData,
  heroImages,
  setHeroImages,
  logoImages,
  setLogoImages,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ["places"],
  });
  const [files, setFiles] = useState([]);
  const [isLogoLoading, setIsLogoLoading] = useState(false);
  const [isLogoPreviewVisible, setLogoPreviewVisible] = useState(false);
  const [isHeroLoading, setIsHeroLoading] = useState(false);
  const [isHeroPreviewVisible, setHeroPreviewVisible] = useState(false);
  const [localesData, setLocalesData] = useState([]);
  const [locales, setLocales] = useState([]);
  const { t } = useTranslation();

  const watchLocation = Form.useWatch("discount_location", form);
  const watchDefaultLanguage = Form.useWatch("defaultLocale", form);
  const watchLocalesValues = Form.useWatch("locales", form);

  // Change merchant hero image
  const handleHeroImageChange = (data) => {
    setHeroImage([...data]);
    setFiles([...files, ...data]);
  };

  // Change logo image
  const handleLogoImageChange = (data) => {
    setLogoImage([...data]);
    setFiles([...files, ...data]);
  };

  // Handle hero image upload
  const handleBeforeUploadHero = async (file) => {
    const extension = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    const currentFileIndexOf = files.findIndex((i) => i.name === file.name);
    if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
      message.open({
        type: "error",
        content: "Only JPG, JPEG and PNG extensions are allowed",
      });
      form.setFieldValue("hero_image", undefined);
      return Upload.LIST_IGNORE;
    } else if (currentFileIndexOf !== -1) {
      message.open({
        type: "error",
        content: "File name already exists",
      });
      return Upload.LIST_IGNORE;
    } else if (file.size > 10000000) {
      message.open({
        type: "error",
        content: "File size cannot be larger then 10MB.",
      });
      return Upload.LIST_IGNORE;
    } else {
      setIsHeroLoading(true);
      const uploadFile = await uploadFiles({
        fileFor: "benefit",
        isUploadIpfs: false,
        files: file,
      });
      setIsHeroLoading(false);
      if (uploadFile?.status === 200) {
        const uploadArray = [
          {
            name: uploadFile.data.s3Url.split("/").reverse()[0],
            resourceUrl: uploadFile.data.s3Url,
            type: "heroImage",
          },
        ];
        handleHeroImageChange(uploadArray);
      }
      setIsHeroLoading(false);
    }
  };

  //Handle logo image upload
  const handleBeforeUploadLogo = async (file) => {
    const extension = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    const currentFileIndexOf = files.findIndex((i) => i.name === file.name);
    if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.ONLY")} JPG, JPEG and PNG ${t(
          "FILE_UPLOAD.EXTENSIONS_ALLOWED"
        )}`,
      });
      form.setFieldValue("logo_image", undefined);
      return Upload.LIST_IGNORE;
    } else if (currentFileIndexOf !== -1) {
      message.open({
        type: "error",
        content: t("FILE_UPLOAD.EXISTS"),
      });
      return Upload.LIST_IGNORE;
    } else if (file.size > 10000000) {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.SIZE_ERROR")} 10${t("MB")}`,
      });
      return Upload.LIST_IGNORE;
    } else {
      setIsLogoLoading(true);
      const uploadFile = await uploadFiles({
        fileFor: "benefit",
        isUploadIpfs: false,
        files: file,
      });

      setIsLogoLoading(false);
      if (uploadFile?.status === 200) {
        const uploadArray = [
          {
            name: uploadFile.data.s3Url.split("/").reverse()[0],
            resourceUrl: uploadFile.data.s3Url,
            type: "logoImage",
          },
        ];
        handleLogoImageChange(uploadArray);
      }
      setIsLogoLoading(false);
    }
  };
  // Removing an image from the hero image array using the delete button
  const handleDeleteClickHero = (fileName) => {
    setHeroImage([]);
    form.setFieldValue("hero_image", undefined);
  };
  const handleDeleteClickLogo = (fileName) => {
    setLogoImage([]);
    form.setFieldValue("logo_image", undefined);
  };
  
  useEffect(() => {
    form.setFieldValue("logo_image", logoImage[0]);
    form.setFieldValue("hero_image", heroImage[0]);
  });

  useEffect(() => {
    const getLocales = async () => {
      const data = await getLocalesFromDb();
      const localesData = data.map((locale) => ({
        value: locale.localeId,
        label: `${locale.language.language} (${locale.country.country})`,
      }));
      setLocales(localesData);
    };
    getLocales();
  }, []);

  useEffect(() => {
    setLocales(
      locales.filter((locale) => {
        return !localesData.find((el) => el.localeId === locale.value);
      })
    );
  }, [localesData]);

  const onClose = () => {
    onCancel();
  };

  return (
    <Modal
      title={
        <ModalHeader
          title={isEdit ? t("MERCHANT.EDIT") : t("MERCHANT.NEW")}
          breadcrumbs={BREADCRUMB_MERCHANT_CREATE(t)}
        />
      }
      open={isOpen}
      centered
      width={1200}
      onCancel={onClose}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      footer={[
        <Button buttonType="cancel" onClick={onClose} key="cancel-button">
          {t("CANCEL")}
        </Button>,
        <Button
          onClick={onSubmitClick}
          loading={loading || isDataLoading}
          key="create-button"
        >
          {isDataLoading
            ? `${t("LOADING")}...`
            : isEdit
            ? t("MERCHANT.UPDATE")
            : t("MERCHANT.CREATE")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        disabled={loading || isDataLoading}
        requiredMark={false}
        preserve={false}
      >
        <Row gutter={52}>
          <Col xs={24} md={24}>
            <h3>{t("MERCHANT.DETAILS")}</h3>

            <Item
              label={`${t("MERCHANT.NAME")} :`}
              name="merchant_name"
              rules={[{ required: true, message: t("MERCHANT.NAME_ERROR") }]}
            >
              <Input placeholder={t("MERCHANT.NAME")} />
            </Item>
            <Item label={`${t("MERCHANT.WEBSITE")} :`} name="merchant_url">
              <Input placeholder={t("MERCHANT.WEBSITE")} />
            </Item>
            <Item
              label={`${t("MERCHANT.PHONE")} :`}
              name="merchant_phoneNumber"
            >
              <Input placeholder={t("MERCHANT.PHONE_PLACEHOLDER")} />
            </Item>
            <Item
              label={`${t("MERCHANT.EMAIL")} :`}
              name="merchant_email"
              rules={[{ type: "email", message: t("MERCHANT.EMAIL_ERROR") }]}
            >
              <Input placeholder={t("EMAIL")} />
            </Item>
            <Row>
              <Item
                label={`${t("MERCHANT.CATEGORY")} :`}
                name="categoryId"
                rules={[
                  {
                    required: true,
                    message: t("MERCHANT.SELECT_CATEGORY"),
                  },
                ]}
              >
                <CategoryTreeSelect
                  name="categoryId"
                  style={{ width: 250 }}
                />
              </Item>
            </Row>
            <Row>
              <Item
                label={`${t("MERCHANT.TYPE")} :`}
                name="type"
                rules={[
                  {
                    required: true,
                    message: t("MERCHANT.SELECT_TYPE"),
                  },
                ]}
              >
                <Select
                  style={{ width: 200 }}
                  className="mr-2"
                  placeholder={t("MERCHANT.SELECT_TYPE")}
                  options={[
                    {
                      value: "retailMerchant",
                      label: "Retail",
                    },
                    {
                      value: "artist",
                      label: "Artist",
                    },
                  ]}
                />
              </Item>
            </Row>
            <Item label={`${t("MERCHANT.DESC")} :`} name="merchant_description">
              <MDEditor
                overflow={false}
                placeholder={t("ADD_DESCRIPTION")}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
              />
              {/* <Input placeholder={t("ADD_DESCRIPTION")} /> */}
            </Item>

            <Item label={`${t("LOCATION.TITLE")} :`}>
              <Form.List name="discount_location">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, value, ...restField }, index) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          rules={[
                            {
                              required: true,
                              message: t("LOCATION.MISSING_NAME"),
                            },
                          ]}
                        >
                          {isLoaded && (
                            <GooglePlacesAutoComplete
                              index={index}
                              form={form}
                              defaultValue={
                                watchLocation && watchLocation[index]?.name
                              }
                            />
                          )}
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "latitude"]}
                          rules={[
                            {
                              required: true,
                              message: t("LOCATION.MISSING_LAT"),
                            },
                          ]}
                        >
                          <Input
                            disabled={true}
                            placeholder={t("LOCATION.LAT")}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "longitude"]}
                          rules={[
                            {
                              required: true,
                              message: t("LOCATION.MISSING_LONG"),
                            },
                          ]}
                        >
                          <Input
                            disabled={true}
                            placeholder={t("LOCATION.LONG")}
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        {t("LOCATION.ADD")}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Item>
            <Item
              label={`${t("DEFAULT_LANGUAGE")}:`}
              name="defaultLocale"
              rules={[
                {
                  required: true,
                  message: t("DEFAULT_LANGUAGE_ERROR"),
                },
              ]}
            >
              <Select
                style={{ width: 300 }}
                options={locales.map((localEl) => ({
                  ...localEl,
                  disabled: !!watchLocalesValues?.find?.(
                    (el) => el?.localeId === localEl.value
                  ),
                }))}
                placeholder={t("SELECT_DEFAULT_LANGUAGE")}
              />
            </Item>
            <Item
              label={`${t("HERO_IMAGE")} :`}
              name="hero_image"
              extra={`${t("FILE_TYPES_SUPPORTED")}: JPG, JPEG, PNG ${t(
                "MAX_SIZE"
              )}: 10${t("MB")}`}
              rules={[
                {
                  required: isEdit && heroImage.length !== 0 ? false : true,
                  message: t("UPLOAD_HERO_IMAGE"),
                },
              ]}
            >
              <Dragger
                showUploadList={false} //Set to false
                beforeUpload={handleBeforeUploadHero} //Check image validations
                disabled={isHeroLoading}
                accept="image/jpg, image/jpeg, image/png"
                customRequest={() => null}
                fileList={heroImage}
                maxCount={1}
              >
                {isHeroLoading ? (
                  <>
                    <LoadingOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                    <p className="ant-upload-hint">{`${t("UPLOADING")}...`}</p>
                  </>
                ) : (
                  <>
                    <UploadOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                    <p className="ant-upload-hint">{t("UPLOAD")}</p>
                  </>
                )}
              </Dragger>
            </Item>
            {heroImage.length !== 0 && (
              <Item label={`${t("HERO_IMAGE")} :`}>
                <List
                  dataSource={heroImage}
                  className="upload-list"
                  style={{ marginBottom: 20 }}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <DeleteOutlined
                          onClick={() => handleDeleteClickHero(item.name)}
                        />,
                        <EyeOutlined
                          onClick={() =>
                            setHeroPreviewVisible(!isHeroPreviewVisible)
                          }
                        />,
                      ]}
                    >
                      <div>
                        <span className="item-index">{index + 1}.</span>{" "}
                        <span className="item-name">
                          {item.name ?? t("HERO_IMAGE_FILE")}
                        </span>
                      </div>
                    </List.Item>
                  )}
                />
              </Item>
            )}

            {heroImage[0]?.resourceUrl && (
              <Image
                className={Styles.imagePreview}
                src={heroImage[0].resourceUrl}
                preview={{
                  visible: isHeroPreviewVisible,
                  onVisibleChange: (visible, prevVisible) =>
                    setHeroPreviewVisible(visible),
                }}
              />
            )}
            <Item
              label={`${t("LOGO_IMAGE")} :`}
              name="logo_image"
              extra={`${t("FILE_TYPES_SUPPORTED")}: JPG, JPEG, PNG ${t(
                "MAX_SIZE"
              )}: 10${t("MB")}`}
              rules={[
                {
                  required: isEdit && files.length !== 0 ? false : true,
                  message: t("UPLOAD_LOGO_IMAGE"),
                },
              ]}
            >
              <Dragger
                showUploadList={false}
                beforeUpload={handleBeforeUploadLogo}
                disabled={isLogoLoading}
                accept="image/jpg, image/jpeg, image/png"
                customRequest={() => null}
                fileList={logoImage}
                maxCount={1}
              >
                {isLogoLoading ? (
                  <>
                    <LoadingOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                    <p className="ant-upload-hint">{`${t("UPLOADING")}...`}</p>
                  </>
                ) : (
                  <>
                    <UploadOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                    <p className="ant-upload-hint">{t("UPLOAD")}</p>
                  </>
                )}
              </Dragger>
            </Item>
            {logoImage.length !== 0 && (
              <Item label={`${t("LOGO_IMAGE")} :`}>
                <List
                  dataSource={logoImage}
                  className="upload-list"
                  style={{ marginBottom: 20 }}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <DeleteOutlined
                          onClick={() => handleDeleteClickLogo(item.name)}
                        />,
                        <EyeOutlined
                          onClick={() =>
                            setLogoPreviewVisible(!isLogoPreviewVisible)
                          }
                        />,
                      ]}
                    >
                      <div>
                        <span className="item-index">{index + 1}.</span>{" "}
                        <span className="item-name">
                          {item.name ?? t("LOGO_IMAGE_FILE")}
                        </span>
                      </div>
                    </List.Item>
                  )}
                />
              </Item>
            )}

            {logoImage[0]?.resourceUrl && (
              <Image
                className={Styles.imagePreview}
                src={logoImage[0].resourceUrl}
                preview={{
                  visible: isLogoPreviewVisible,
                  onVisibleChange: (visible, prevVisible) =>
                    setLogoPreviewVisible(visible),
                }}
              />
            )}
            <Item
              label={`${t("MERCHANT.SELF_REDEEM")}:`}
              name="merchant_selfRedeem"
            >
              <Radio.Group style={{ width: "100%" }} defaultValue="no">
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value="yes">
                      {t("YES")}
                    </Radio>
                  </Col>
                  <Col xs={24} md={12}>
                    <Radio style={{ width: "100%" }} value="no">
                      {t("NO")}
                    </Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Item>
            <Item
              label={`${t("MERCHANT.PARTNER_CODE")} :`}
              name="partnerCode"
              rules={[
                { required: true, message: t("MERCHANT.PARTNER_CODE_ERROR") },
              ]}
            >
              <Input placeholder={t("MERCHANT.PARTNER_CODE")} />
            </Item>
            <Item
              label={`${t("MERCHANT.PARTNER_TYPE")} :`}
              name="partnerType"
              rules={[
                {
                  required: true,
                  message: t("MERCHANT.SELECT_PARTNER_TYPE"),
                },
              ]}
            >
              <Select
                style={{ width: 200 }}
                className="mr-2"
                placeholder={t("MERCHANT.SELECT_PARTNER_TYPE")}
                options={[
                  {
                    value: "Merchant",
                    label: "Merchant",
                  },
                  {
                    value: "Affiliate",
                    label: "Affiliate",
                  },
                  {
                    value: "Franchisee",
                    label: "Franchisee",
                  },
                ]}
              />
            </Item>
          </Col>
        </Row>
        <MerchantLocaleDetails
          form={form}
          locales={locales}
          setLocales={setLocales}
          localesData={localesData}
          setLocalesData={setLocalesData}
          blockedLocales={watchDefaultLanguage}
          isEdit={isEdit}
          heroImages={heroImages}
          setHeroImages={setHeroImages}
          logoImages={logoImages}
          setLogoImages={setLogoImages}
        />
      </Form>
    </Modal>
  );
};

export default NewMerchant;
