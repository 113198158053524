// import Third party and React
import { useEffect, useMemo, useState } from "react";
import {
  Space,
  Row,
  Col,
  Form,
  Skeleton,
  Popconfirm,
  Tooltip,
  Dropdown,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// import utils, API Utils and helper functions
import { BREADCRUMB_SWEEPSTAKES_TOKEN } from "../../../../utils/benefits/constant";
import {
  addBenefitToDb,
  cloneCurrentBenefitinDB,
  deleteBenefitFromDb,
  getAllBenefitsFromDb,
  updateBenefitToDb,
  getBenefitPreviewToken,
} from "../apiUtils";
import {
  debounce,
  getCategoryIdFromName,
  getMerchantCategoryAndSubCategory,
} from "../../../../utils/helper";
import constant from "../../../../config/constant";

// import custom components
import {
  PageHeader,
  Button,
  Table,
  Tag,
  Card,
  NoData,
  Micons,
  Modal,
} from "../../../../components/customAntd";
import DiscountCodesFilters from "./filters";
import NewCode from "./newCode";
import AddBenefitsModal from "../../../../components/addBenefitsModal";
import { useAppContext } from "../../../../components/context/app.context";
import SweepstakeRegistrations from "./registrations";
import DownloadRegistrations from "./downloadRegistrations";
import NotificationsModal from "./notifications";
import DefaultImage from "../../../../assets/images/default-img.jpg";

// import custom Style
import Styles from "./styles/discountCodes.module.scss";

const { Meta } = Card;

const Sweepstakes = () => {
  const [form] = Form.useForm();
  const [displayType, setDisplayType] = useState("grid");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [clone, setClone] = useState(false);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [showRegistrations, setShowRegistrations] = useState(false);
  const [showRegDownload, setShowRegDownload] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [heroImage, setHeroImage] = useState([]);
  const [filters, setFilters] = useState({
    filterCondition: { benefitType: "sweepstakes" },
  });
  const [isBenefitsModalOpen, setIsBenefitsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // For delete confirmation of sweepstake
  const [isDeletingBenefit, setIsDeletingBenefit] = useState(false);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);

  const { t } = useTranslation();
  const { currentLocale, currentUserData, categories } =
    useAppContext();

  // useMemo is used to prevent the re-creation of the 'columns' variable on every render.
  const columns = useMemo(
    () => [
      {
        dataIndex: "previewResourceUrl",
        width: 100,
        render: (_, record) => (
          <img
            src={
              record?.locales?.find?.(
                (locale) => locale.localeId === currentLocale
              )?.previewResourceUrl ??
              record?.previewResourceUrl ??
              DefaultImage
            }
            alt="Preview"
            height={50}
            width={50}
            style={{ borderRadius: 5, objectFit: "cover" }}
          />
        ),
      },
      {
        title: t("ID"),
        dataIndex: "id",
        key: "id",
      },
      {
        title: t("DISCOUNT_CODES.CODE_NAME"),
        dataIndex: "title",
        key: "title",
        width: "20%",
        sorter: (a, b) => a.title.length - b.title.length,
        render: (_, { title, locales }) =>
          locales?.find?.((locale) => locale.localeId === currentLocale)
            ?.title ?? title,
      },
      {
        title: t("SWEEPSTAKES.ACTIVE_DATE_COLUMN"),
        key: "activeDate",
        dataIndex: "activeDate",
        render: (_, { activeDate }) => (
          <>
            <Tag isDisabled>
              {activeDate ? dayjs(activeDate).format("MM/DD/YYYY") : "--"}
            </Tag>
          </>
        ),
      },
      {
        title: t("SWEEPSTAKES.REGISTRATION_PERIOD_COLUMN"),
        key: "startDate",
        dataIndex: "startDate",
        render: (_, { startDate, endDate }) => (
          <>
            {
              <>
                <Tag isDisabled>{dayjs(startDate).format("MM/DD/YYYY")}</Tag>
                <span>-</span>
                <Tag isDisabled style={{ marginLeft: 5 }}>
                  {dayjs(endDate).format("MM/DD/YYYY")}
                </Tag>
              </>
            }
          </>
        ),
      },
      {
        title: t("SWEEPSTAKES.RESULT_DATE_COLUMN"),
        key: "sweepStake",
        dataIndex: "sweepStake",
        render: (_, { sweepStake }) => (
          <>
            <Tag isDisabled>
              {sweepStake?.resultAnnouncementDate
                ? dayjs(sweepStake?.resultAnnouncementDate).format("MM/DD/YYYY")
                : "--"}
            </Tag>
          </>
        ),
      },
      {
        title: t("SWEEPSTAKES.VISIBLE_UNTIL_COLUMN"),
        key: "sweepStake",
        dataIndex: "sweepStake",
        render: (_, { sweepStake }) => (
          <>
            <Tag isDisabled>
              {sweepStake?.showUntilDate
                ? dayjs(sweepStake?.showUntilDate).format("MM/DD/YYYY")
                : "--"}
            </Tag>
          </>
        ),
      },
      {
        title: t("ACTION"),
        key: "action",
        width: "13%",
        render: (_, record) => (
          <Space size="middle">
            <Button type="link">
              <Tooltip title={t("EDIT")}>
                <Micons
                  icon="edit"
                  className="table-action-icon"
                  onClick={() => handleEditClick(record)}
                />
              </Tooltip>
            </Button>
            <Tooltip title={t("PREVIEW")}>
              <Button type="link" onClick={() => handlePreviewClick(record)}>
                <Micons icon="visibility" className="table-action-icon" />
              </Button>
            </Tooltip>
            <Dropdown
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: "clone",
                    label: t("CLONE"),
                    icon: (
                      <Micons
                        icon="content_copy"
                        className="table-action-icon"
                      />
                    ),
                  },
                  {
                    key: "delete",
                    label: t("DELETE"),
                    icon: (
                      <Micons icon="delete" className="table-action-icon" />
                    ),
                  },
                  {
                    key: "registrations",
                    label: t("REGISTRATIONS"),
                    icon: (
                      <Micons
                        icon="app_registration"
                        className="table-action-icon"
                      />
                    ),
                  },
                  {
                    key: "download",
                    label: t("DOWNLOAD_REGISTRATIONS"),
                    icon: (
                      <Micons icon="download" className="table-action-icon" />
                    ),
                  },
                  !record?.sweepStake?.isFastPass && {
                    key: "attach",
                    label: t("SWEEPSTAKES.ATTACH_BENEFITS"),
                    icon: (
                      <Micons icon="delete" className="table-action-icon" />
                    ),
                  },
                  record?.sweepStake && {
                    key: "notifications",
                    label: t("NOTIFICATIONS"),
                    icon: (
                      <Micons
                        icon="notifications"
                        className="table-action-icon"
                      />
                    ),
                  },
                ],
                onClick: (el) => {
                  const { key } = el;
                  if (key === "delete") {
                    setCurrentRecord(record);
                    setIsDeleteModalOpen(true);
                  } else if (key === "clone") {
                    handleCloneClick(record);
                  } else if (key === "registrations") {
                    handleRegistrationClick(record);
                  } else if (key === "download") {
                    handleRegDownloadClick(record);
                  } else if (key === "attach") {
                    setIsEdit(true);
                    setCurrentRecord(record);
                    setIsBenefitsModalOpen(true);
                  } else if (key === "notifications") {
                    setCurrentRecord(record);
                    setIsNotificationsModalOpen(true);
                  }
                },
              }}
              style={{ width: 200 }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Micons icon="more_vert" className="table-action-icon" />
              </a>
            </Dropdown>
          </Space>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, isEdit]
  );

  // Get all discount codes initially
  useEffect(() => {
    const fetchCodes = async () => {
      setIsLoading(true);
      const getAllCodes = await getAllBenefitsFromDb(filters);
      if (getAllCodes?.status === 200) {
        setData(getAllCodes.data);
      }
      setIsLoading(false);
    };
    fetchCodes();
    if (clone) setClone(false);
  }, [filters, clone]);

  // Switching between a list view and a grid view.
  const onViewChange = () => {
    setDisplayType(displayType === "list" ? "grid" : "list");
  };

  // Handler for the cancel button
  const handleCancleClick = () => {
    form.resetFields();
    setIsSubmitting(false);
    setIsDataLoading(false);
    setIsOpen(false);
    setIsEdit(false);
    setEditData(null);
  };

  // Handler for the Preview  button
  const handlePreviewClick = async (record) => {
    setPreviewUrl(null);
    const tokenCode = await getBenefitPreviewToken(record.id);
    if (tokenCode?.status === 200) {
      const { companyId } = currentUserData;
      // const proxyCompanyId = localStorage.getItem("proxyCompanyId")
      const url = `${constant.config.STOREFRONT_SITE_URL}${constant.config.STOREFRONT_SITE_TITLES[companyId]}/benefits/${record.id}?token=${tokenCode.data.token}`;
      setPreviewUrl(url);
      setShowPreview(true);
    }
  };

  // Handler for the registration  button
  const handleRegistrationClick = (record) => {
    setShowRegistrations(true);
    setCurrentRecord(record);
  };

  // Handler for the download registration  button
  const handleRegDownloadClick = (record) => {
    setShowRegDownload(true);
    setCurrentRecord(record);
  };

  // Submitting the final form after it has passed all of the validation checks
  const handleFormSubmit = async (data) => {
    setIsSubmitting(true);
    let rewards = [];
    if (Array.isArray(data)) {
      //Array of benefit ids from addbenefits modal or deleting benefits
      rewards = data;
      data = isDeletingBenefit ? editData : currentRecord;
      if (
        currentRecord?.sweepStake?.rewards?.length > 0 ||
        editData?.sweepStake?.rewards?.length > 0
      ) {
        if (isDeletingBenefit) {
          rewards = editData.sweepStake.rewards
            .filter((el) => !rewards.includes(el.id))
            .map((el) => `${el.id}`);
        } else {
          rewards = [
            ...currentRecord.sweepStake.rewards.map((el) => `${el.id}`),
            ...rewards,
          ];
        }
      }
    }
    data?.locales?.forEach((locale) => {
      locale.previewResourceUrl = heroImage?.[0]?.resourceUrl;
    });

    const finalData = {
      title:
        isBenefitsModalOpen || isDeletingBenefit ? data.title : data.code_name,
      shortDescription:
        isBenefitsModalOpen || isDeletingBenefit
          ? data.shortDescription
          : data.code_short_description,
      categoryId: data.categoryId,
      description:
        isBenefitsModalOpen || isDeletingBenefit
          ? data.description
          : data.code_description,
      disclaimer: data.disclaimer,
      startDate:
        isBenefitsModalOpen || isDeletingBenefit
          ? dayjs(data.startDate).set("seconds", 0)
          : dayjs(data.redeemable_period[0]).set("seconds", 0).format(),
      endDate:
        isBenefitsModalOpen || isDeletingBenefit
          ? dayjs(data.endDate).set("seconds", 0)
          : dayjs(data.redeemable_period[1]).set("seconds", 0).format(),
      status: data.status,
      type: "sweepstakes",
      previewResourceUrl: heroImage[0]?.resourceUrl || data.previewResourceUrl,
      resourceUrl: heroImage[0]?.resourceUrl || data.resourceUrl,
      locales: data.locales,
      defaultLocaleId: data.defaultLocaleId,
      membershipCriteria: data.membershipCriteria,
      showImagesOnly:
        isBenefitsModalOpen || isDeletingBenefit
          ? data.showImagesOnly
          : data.show_images_only,
      activeDate:
        isBenefitsModalOpen || isDeletingBenefit
          ? dayjs(data.activeDate).set("seconds", 0)
          : dayjs(data.active_date).set("seconds", 0),
      sweepStake: data.sweepStake
        ? {
            resultAnnouncementDate:
              isBenefitsModalOpen || isDeletingBenefit
                ? data.sweepStake.resultAnnouncementDate
                : data.sweepStake?.resultAnnouncementDate?.format(),
            rewardCount: parseInt(data.sweepStake.rewardCount),
            rewards: rewards.map((el) => el.toString()),
            showUntilDate:
              isBenefitsModalOpen || isDeletingBenefit
                ? data.sweepStake.showUntilDate
                : data.sweepStake?.showUntilDate?.format() || null,
            isFastPass: data.sweepStake.isFastPass || false,
          }
        : null,
    };

    if (isEdit) {
      finalData.locales.forEach((locale) => {
        delete locale.benefitId;
      });
    }
    const createNewCode = isEdit
      ? await updateBenefitToDb(finalData, data.id)
      : await addBenefitToDb(finalData);
    if (createNewCode?.status === 200) {
      if (isEdit) {
        setData((prev) =>
          prev.map((item) => (item.id === data.id ? createNewCode.data : item))
        );
        if (isDeletingBenefit) setEditData(createNewCode.data);
      } else {
        setData((prev) => [createNewCode.data, ...prev]);
      }
      if (!isDeletingBenefit) {
        handleCancleClick();
      } else {
        setIsDeletingBenefit(false);
      }
    }
    setIsSubmitting(false);
    if (isBenefitsModalOpen) {
      setIsBenefitsModalOpen(false);
    }
  };

  // Handler for the edit 'discount code' button
  const handleEditClick = (record) => {
    const finalData = {
      id: record.id,
      categoryId: record.categoryId,
      code_name: record.title,
      code_short_description: record.shortDescription,
      code_description: record.description,
      disclaimer: record.disclaimer,
      type: record.type,
      redeemable_period: [
        record.startDate
          ? dayjs(record.startDate).set("seconds", 0)
          : dayjs().set("seconds", 0),
        record.endDate
          ? dayjs(record.endDate).set("seconds", 0)
          : dayjs().add(1, "month").set("seconds", 0),
      ],
      status: record.status,
      locales: record.locales,
      defaultLocaleId: record.defaultLocaleId,
      membershipCriteria: record.membershipCriteria,
      show_images_only: record.showImagesOnly,
      active_date: record.activeDate
        ? dayjs(record.activeDate).set("seconds", 0)
        : dayjs().set("seconds", 0),
      standaloneSweepstake: record.sweepStake ? true : false,
      sweepStake: record.sweepStake
        ? {
            resultAnnouncementDate: record.sweepStake.resultAnnouncementDate
              ? dayjs(record.sweepStake.resultAnnouncementDate)
              : null,
            rewardCount: record.sweepStake.rewardCount,
            rewards: record.sweepStake?.rewards,
            showUntilDate: record.sweepStake?.showUntilDate
              ? dayjs(record.sweepStake?.showUntilDate)
              : null,
            isFastPass: record.sweepStake.isFastPass || false,
            registrationsCount: record._count?.sweepstakeRegistrations || 0,
          }
        : null,
    };

    if (record.previewResourceUrl) {
      setHeroImage([
        {
          name: record.previewResourceUrl.split("/").pop(),
          resourceUrl: record.previewResourceUrl,
          type: "heroImage",
        },
      ]);
    }
    setIsEdit(true);
    setEditData(record);
    form.setFieldsValue(finalData);
    setIsOpen(true);
  };

  // Handler for the delete 'discount code' button
  const handleDelete = async (recordId) => {
    const deletedCode = await deleteBenefitFromDb(recordId);
    if (deletedCode?.status === 200) {
      setData((prev) => prev.filter((item) => item.id !== deletedCode.data.id));
    }
  };

  // Handler for the delete 'discount code' button
  const handleCloneClick = async (record) => {
    const clonedCode = await cloneCurrentBenefitinDB(record.id);
    if (clonedCode?.status === 200) {
      setClone(true);
    }
  };

  // Handler for the search input
  const handleSearch = debounce((e) => {
    const searchTerm = e.target.value;
    if (searchTerm !== "") {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            search: searchTerm,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.search;
      setFilters(filtersCopy);
    }
  });

  // Handler for the filter select
  const handleFilterByChange = (value) => {
    if (value) {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            status: value,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.status;
      setFilters(filtersCopy);
    }
  };

  // Handler for the filter select
  const handleCategoryFilterChange = (value) => {
    if (value) {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            categoryId: value,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.categoryId;
      setFilters(filtersCopy);
    }
  };

  const renderPreview = () => {
    return (
      <object
        data={previewUrl}
        width="428px"
        height="926px"
        alt="Benefit Preview"
        title="Benefit Preview"
        style={{ paddingTop: "25px" }}
      />
    );
  };

  const actions = (item) => {
    const allActions = [
      <Tooltip title={t("EDIT")}>
        <Button type="link" onClick={() => handleEditClick(item)}>
          <Micons icon="edit" className="table-action-icon" />
        </Button>
      </Tooltip>,
      <Tooltip title={t("PREVIEW")}>
        <Button type="link" onClick={() => handlePreviewClick(item)}>
          <Micons icon="visibility" className="table-action-icon" />
        </Button>
      </Tooltip>,
    ];

    if (item.sweepStake && item.sweepStake?.id) {
      allActions.push(
        <Tooltip title={t("REGISTRATIONS")}>
          <Button type="link" onClick={() => handleRegistrationClick(item)}>
            <Micons icon="app_registration" className="table-action-icon" />
          </Button>
        </Tooltip>
      );
      allActions.push(
        <Tooltip title={t("DOWNLOAD_REGISTRATIONS")}>
          <Button type="link" onClick={() => handleRegDownloadClick(item)}>
            <Micons icon="download" className="table-action-icon" />
          </Button>
        </Tooltip>
      );
      if (!item.sweepStake?.isFastPass) {
        allActions.push(
          <Tooltip title={t("SWEEPSTAKES.ATTACH_BENEFITS")}>
            <Button
              type="link"
              onClick={() => {
                setIsEdit(true);
                setCurrentRecord(item);
                setIsBenefitsModalOpen(true);
              }}
            >
              <Micons icon="assignment" className="table-action-icon" />
            </Button>
          </Tooltip>
        );
      }
      allActions.push(
        <Dropdown
          menu={{
            items: [
              {
                key: "clone",
                label: t("CLONE"),
                icon: (
                  <Micons icon="content_copy" className="table-action-icon" />
                ),
              },
              {
                key: "delete",
                label: t("DELETE"),
                icon: <Micons icon="delete" className="table-action-icon" />,
              },
              item?.sweepStake && {
                key: "notifications",
                label: t("NOTIFICATIONS"),
                icon: (
                  <Micons icon="notifications" className="table-action-icon" />
                ),
              },
            ],
            onClick: (el) => {
              if (el.key === "delete") {
                setCurrentRecord(item);
                setIsDeleteModalOpen(true);
              } else if (el.key === "clone") {
                handleCloneClick(item);
              } else if (el.key === "notifications") {
                setCurrentRecord(item);
                setIsNotificationsModalOpen(true);
              }
            },
          }}
          style={{ width: 200 }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Micons icon="more_vert" className="table-action-icon" />
          </a>
        </Dropdown>
      );
    } else {
      allActions.push(
        <Popconfirm
          title={t("DISCOUNT_CODES.DELETE_COUPON_CONFIRM")}
          onConfirm={() => handleDelete(item.id)}
          onCancel={() => {}}
          okText={t("YES")}
          cancelText={t("NO")}
        >
          <Tooltip title={t("DELETE")}>
            <Micons icon="delete" className="table-action-icon" />
          </Tooltip>
        </Popconfirm>,

        <Tooltip title={t("DISCOUNT_CODES.CLONE")}>
          <Button type="link" onClick={() => handleCloneClick(item)}>
            <Micons icon="content_copy" className="table-action-icon" />
          </Button>
        </Tooltip>
      );
    }

    return allActions;
  };

  return (
    <>
      <PageHeader
        title={t("BENEFIT_TYPES.SWEEPSTAKES.TITLE")}
        breadcrumbs={BREADCRUMB_SWEEPSTAKES_TOKEN(t)}
        extra={
          <div style={{ display: "flex" }}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setIsOpen(true);
                setHeroImage([]);
              }}
            >
              {t("SWEEPSTAKES.NEW_CODE")}
            </Button>
          </div>
        }
      />
      <DiscountCodesFilters
        onViewChange={onViewChange}
        type={displayType}
        onSearch={handleSearch}
        onFilterByChange={handleFilterByChange}
        onCategoryFilterChange={handleCategoryFilterChange}
        categories={categories}
      />
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {data.length === 0 ? (
            <NoData />
          ) : (
            <>
              {displayType === "list" ? (
                <Table
                  columns={columns}
                  dataSource={data}
                  rowKey={(record) => record.id}
                  locale={{
                    triggerDesc: t("SORT_DESCENDING"),
                    triggerAsc: t("SORT_ASCENDING"),
                    cancelSort: t("CANCEL_SORT"),
                  }}
                />
              ) : (
                <Row gutter={[25, 25]}>
                  {data.map((item) => {
                    const currentLocaleData = item?.locales?.find(
                      (locale) => locale.localeId === currentLocale
                    );
                    return (
                      <Col
                        xs={24}
                        md={12}
                        lg={6}
                        key={`${item.title}-${item.couponCode}}`}
                      >
                        <Card
                          className={Styles["sweepstake-card"]}
                          cover={
                            <img
                              src={
                                currentLocaleData?.previewResourceUrl ??
                                item.previewResourceUrl
                              }
                              alt={currentLocaleData?.title ?? item.title}
                              height="200px"
                              width="100%"
                              onClick={() => handleEditClick(item)}
                              className="pointer"
                            />
                          }
                          actions={actions(item)}
                        >
                          <p style={{ marginBottom: 5 }}>{`${t("ID")}: ${
                            item.id
                          }`}</p>
                          <div className={Styles["active-date"]}>
                            <p>
                              <strong>{`${t(
                                "SWEEPSTAKES.ACTIVE_DATE_COLUMN"
                              )}:`}</strong>
                              {dayjs(item.activeDate).format("MM/DD/YYYY")}
                            </p>
                          </div>
                          <Meta
                            title={currentLocaleData?.title ?? item.title}
                          />
                          <div className={Styles["redeemable-period"]}>
                            <strong>{`${t(
                              "SWEEPSTAKES.REGISTRATION_PERIOD_COLUMN"
                            )}:`}</strong>
                            <p>{`${dayjs(item.startDate).format(
                              "MM/DD/YY"
                            )} - ${dayjs(item.endDate).format(
                              "MM/DD/YY hh:mm A"
                            )}`}</p>
                          </div>
                          {item?.sweepStake &&
                            item.sweepStake?.resultAnnouncementDate && (
                              <p className={Styles["result-date"]}>
                                <strong>{`${t(
                                  "SWEEPSTAKES.RESULT_DATE_COLUMN"
                                )}: `}</strong>
                                <span>
                                  {`${dayjs(
                                    item.sweepStake.resultAnnouncementDate
                                  ).format("MM/DD/YY hh:mm A")}`}
                                </span>
                              </p>
                            )}
                          {item?.sweepStake &&
                            item.sweepStake?.showUntilDate && (
                              <p className={Styles["result-date"]}>
                                <strong>{`${t(
                                  "SWEEPSTAKES.VISIBLE_UNTIL_COLUMN"
                                )}: `}</strong>
                                <span>
                                  {`${dayjs(
                                    item.sweepStake.showUntilDate
                                  ).format("MM/DD/YY hh:mm A")}`}
                                </span>
                              </p>
                            )}
                          {item?._count &&
                            item._count?.sweepStakesRegistrations >= 0 && (
                              <p className={Styles["result-date"]}>
                                <strong>{`${t(
                                  "SWEEPSTAKES.REGISTRATION_COUNT"
                                )}: `}</strong>
                                <span>
                                  {`${item._count?.sweepStakesRegistrations}`}
                                </span>
                              </p>
                            )}
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </>
          )}
        </>
      )}

      <NewCode
        form={form}
        isOpen={isOpen}
        onCancel={handleCancleClick}
        onSubmitClick={() => form.submit()}
        onSubmit={handleFormSubmit}
        loading={isSubmitting || isDeletingBenefit}
        isDataLoading={isDataLoading}
        isEdit={isEdit}
        editData={editData}
        setHeroImage={setHeroImage}
        heroImage={heroImage}
        setIsDeletingBenefit={setIsDeletingBenefit}
        categories={categories}
      />
      <Modal
        width={500}
        height={1370}
        destroyOnClose
        open={showPreview}
        onCancel={() => {
          setShowPreview(false);
        }}
        onOk={() => {
          setShowPreview(false);
        }}
        cancelText={t("CANCEL")}
        okText={t("OK")}
      >
        {renderPreview()}
      </Modal>
      {showRegistrations && (
        <SweepstakeRegistrations
          open={showRegistrations}
          onOk={() => {
            setShowRegistrations(false);
            setCurrentRecord(null);
          }}
          record={currentRecord}
          setData={setData}
        />
      )}
      {showRegDownload && (
        <DownloadRegistrations
          open={showRegDownload}
          onOk={() => {
            setShowRegDownload(false);
            setCurrentRecord(null);
          }}
          record={currentRecord}
          setData={setData}
        />
      )}
      <AddBenefitsModal
        open={isBenefitsModalOpen}
        isLoading={isSubmitting}
        endPoint={`benefit/all`}
        onCancel={() => setIsBenefitsModalOpen(false)}
        onSubmit={handleFormSubmit}
        hideBenefitsList={currentRecord?.sweepStake?.rewards.map((el) => el.id)}
        isSweepStake
      />
      <Modal
        title={`${t("DELETE")} ${
          currentRecord?.locales?.find?.(
            (locale) => locale.localeId === currentLocale
          )?.title ?? currentRecord?.title
        }?`}
        open={isDeleteModalOpen}
        closable={false}
        footer={[
          <Button
            type="default"
            onClick={() => {
              setCurrentRecord(null);
              setIsDeleteModalOpen(false);
            }}
            disabled={isSubmitting}
          >
            {t("CANCEL")}
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              handleDelete(currentRecord.id);
              setIsDeleteModalOpen(false);
            }}
            disabled={isSubmitting}
          >
            {t("DELETE")}
          </Button>,
        ]}
      >
        <p style={{ padding: "20px 0px", fontSize: 15 }}>
          {t("SWEEPSTAKES.DELETE_CONFIRMATION")}
        </p>
      </Modal>
      <NotificationsModal
        isOpen={isNotificationsModalOpen}
        currentRecord={currentRecord}
        onCancel={() => {
          setCurrentRecord(null);
          setIsNotificationsModalOpen(false);
        }}
      />
    </>
  );
};

export default Sweepstakes;
